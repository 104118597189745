body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Montserrat Medium";  */
}
@font-face {font-family: "Montserrat Medium";
  src: url("https://db.onlinewebfonts.com/t/01c2d48a8d3bbd9730fccb2354765d43.eot"); /* IE9*/
  src: url("https://db.onlinewebfonts.com/t/01c2d48a8d3bbd9730fccb2354765d43.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("https://db.onlinewebfonts.com/t/01c2d48a8d3bbd9730fccb2354765d43.woff2") format("woff2"), /* chrome firefox */
  url("https://db.onlinewebfonts.com/t/01c2d48a8d3bbd9730fccb2354765d43.woff") format("woff"), /* chrome firefox */
  url("https://db.onlinewebfonts.com/t/01c2d48a8d3bbd9730fccb2354765d43.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
  url("https://db.onlinewebfonts.com/t/01c2d48a8d3bbd9730fccb2354765d43.svg#Montserrat Medium") format("svg"); /* iOS 4.1- */
}

@font-face {font-family: "Montserrat";
  src: url("https://db.onlinewebfonts.com/t/210b0e61cbc8cc0051409b37c6babe5a.eot"); /* IE9*/
  src: url("https://db.onlinewebfonts.com/t/210b0e61cbc8cc0051409b37c6babe5a.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("https://db.onlinewebfonts.com/t/210b0e61cbc8cc0051409b37c6babe5a.woff2") format("woff2"), /* chrome firefox */
  url("https://db.onlinewebfonts.com/t/210b0e61cbc8cc0051409b37c6babe5a.woff") format("woff"), /* chrome firefox */
  url("https://db.onlinewebfonts.com/t/210b0e61cbc8cc0051409b37c6babe5a.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
  url("https://db.onlinewebfonts.com/t/210b0e61cbc8cc0051409b37c6babe5a.svg#Montserrat") format("svg"); /* iOS 4.1- */
}

@font-face {font-family: "Montserrat SemiBold";
  src: url("https://db.onlinewebfonts.com/t/06cab1a0b0536008f269a1323d2598b6.eot"); /* IE9*/
  src: url("https://db.onlinewebfonts.com/t/06cab1a0b0536008f269a1323d2598b6.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("https://db.onlinewebfonts.com/t/06cab1a0b0536008f269a1323d2598b6.woff2") format("woff2"), /* chrome firefox */
  url("https://db.onlinewebfonts.com/t/06cab1a0b0536008f269a1323d2598b6.woff") format("woff"), /* chrome firefox */
  url("https://db.onlinewebfonts.com/t/06cab1a0b0536008f269a1323d2598b6.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
  url("https://db.onlinewebfonts.com/t/06cab1a0b0536008f269a1323d2598b6.svg#Montserrat SemiBold") format("svg"); /* iOS 4.1- */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sign-in-btn{
  background-color: #228B22 !important;
  color: #ffffff !important;  
}
.disabled-btn{
  background-color: #9d9b9b !important;
}

.schedule-code-btn{
  right:0;
}

.view-btn{
  background-color: #FFFFFF !important;
  color: #228B22 !important;  
}

.dialog-title{
  background-color:#1D5103 !important;
  color: #ffffff !important; 
  height: 25px; 
}

.login-background {
  height:1500px;
  background: url('https://media.gettyimages.com/photos/ball-and-basketball-court-picture-id183256716?s=612x612');
  background-size: cover;
}

.text-font {
  color: #000000;	font-family: Montserrat;	font-size: 12px;
}